import classes from './index.module.less';

type SkeletonProps = {
	children?: any;
	isLoading?: boolean;
	variant?: 'text' | '';
	className?: string;
	width?: string | number;
	height?: string | number;
	borderRadius?: string;
	style?: object;
};

const Skeleton = ({
	children,
	isLoading = true,
	variant = '',
	className = '',
	width,
	height,
	borderRadius,
	style = {},
}: SkeletonProps) => {
	return (
		<>
			{isLoading && (
				<div
					className={`${classes.skeleton} ${className}`}
					style={{
						width: width,
						minHeight: height,
						height,
						borderRadius: borderRadius ?? variant === 'text' ? '4px' : undefined,
						...style,
					}}
				/>
			)}
			<div className={isLoading ? 'd-none' : ''}>{children}</div>
		</>
	);
};

export default Skeleton;

import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import ConfigProvider from 'antd/lib/config-provider';
import Drawer from 'antd/lib/drawer';
import Dropdown from 'antd/lib/dropdown/dropdown';
import Tabs, { TabsProps } from 'antd/lib/tabs';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import SearchBar from 'src/components/SearchBar';
import Skeleton from 'src/components/Skeleton';
import { useGetGlobalSearch } from 'src/hooks';
import { ISearchResult, ISearchResultGame, ISearchResultNft } from 'src/interface/Search';
import useDebounce from 'src/utils/hooks/useDebounce';
import useDeviceSize from 'src/utils/hooks/useDeviceSize';

import classes from './index.module.less';

const Item = ({
	item,
	onClick,
}: {
	item: ISearchResultGame | ISearchResultNft;
	onClick?: () => void;
}) => {
	if (item.id === 'loading') {
		return (
			<div className={classes.item}>
				<Skeleton className={classes.image} />
				<div className={classNames(classes.info, 'w-100')}>
					<Skeleton
						variant="text"
						className={classNames(classes.title, 'mt-1')}
						height={14}
						width={'95%'}
					/>
					<Skeleton variant="text" className={classes.description} height={12} width={'80%'} />
				</div>
			</div>
		);
	}

	let title = '';
	let description = '';
	let image = '';
	let link = '';

	if (item.type === 'game') {
		const itemG: ISearchResultGame = { ...item };

		title = itemG.name;
		description = 'Game';
		image = itemG.icon_image_url;
		link = `/games/${itemG.slug}`;
	} else {
		const itemN: ISearchResultNft = { ...item };

		title = itemN.name;
		description = `${itemN.type.toUpperCase()} Item / ${itemN.game.name}`;
		image = itemN.image_url;
		link = '#';
	}

	return (
		<Link href={link} onClick={onClick}>
			<div className={classes.item}>
				<ImageC className={classes.image} alt={`image ${title}`} src={image} />
				<div className={classes.info}>
					<div className={classes.title}>{title}</div>
					<div className={classes.description}>{description}</div>
				</div>
			</div>
		</Link>
	);
};

const Empty = ({ minKeywordLen }: any) => {
	return (
		<div className={classes.empty}>
			You must enter at least {minKeywordLen} characters to display search results.
		</div>
	);
};

const Loading = () => {
	return (
		<div className={classes.items}>
			{new Array(3)
				.fill({ id: 'loading' })
				?.map((item, i) => <Item key={item.id + '-' + i} item={item} />)}
		</div>
	);
};

const HeaderSearch = () => {
	const deviceSize = useDeviceSize();

	const [keyword, setKeyword] = useState<string>('');
	const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);

	const debouncedKeyword = useDebounce(keyword, 1000);

	const inputHeaderRef = useRef<any>(null);
	const inputRef = useRef<any>(null);

	const minKeywordLen = 3;
	const keywordLen = keyword?.replace(/\s/g, '')?.length;

	const { data, isLoading: isLoadingSearch } = useGetGlobalSearch(
		keyword,
		debouncedKeyword === keyword && keywordLen >= minKeywordLen,
	);
	const dataSearchResult: ISearchResult = data?.data?.data;

	const isLoading = isLoadingSearch || debouncedKeyword !== keyword;

	const items: TabsProps['items'] = [
		// {
		// key: '1',
		// label: 'All results',
		// children:
		// keywordLen < minKeywordLen ? (
		// <Empty minKeywordLen={minKeywordLen} />
		// ) : isLoading ? (
		// <Loading />
		// ) : (
		// <div className={classes.items}>
		// {dataSearchResult?.all?.map((item, i) => <Item key={item.id + '-' + i} item={item} />)}
		// </div>
		// ),
		// },
		{
			key: '2',
			label: 'Game',
			children:
				keywordLen < minKeywordLen ? (
					<Empty />
				) : isLoading ? (
					<Loading />
				) : (
					<div className={classes.items}>
						{dataSearchResult?.games?.map((item, i) => (
							<Item
								key={item.id + '-' + i}
								item={item}
								onClick={() => setOpenSearchResult(false)}
							/>
						))}
					</div>
				),
		},
		// {
		// key: '3',
		// label: 'NFT Item',
		// children:
		// keywordLen < minKeywordLen ? (
		// <Empty />
		// ) : isLoading ? (
		// <Loading />
		// ) : (
		// <div className={classes.items}>
		// {dataSearchResult?.nfts?.map((item, i) => <Item key={item.id + '-' + i} item={item} />)}
		// </div>
		// ),
		// },
		// {
		// key: '4',
		// label: 'Game Store',
		// children:
		// keywordLen < minKeywordLen ? (
		// <Empty />
		// ) : isLoading ? (
		// <Loading />
		// ) : (
		// <div className={classes.items}>
		// {data?.map((item, i) => <Item key={item.id + '-' + i} item={item} link="#" />)}
		// </div>
		// ),
		// },
	];

	useEffect(() => {
		if (openSearchResult) {
			setTimeout(() => {
				inputRef?.current?.focus();
			}, 100);
		}
	}, [openSearchResult]);

	const configProviderProps = {
		theme: {
			components: {
				Tabs: {
					/* here is your component tokens */
					inkBarColor: 'var(--Gradient-Blue, #0267FF)',
					itemColor: 'rgba(255, 255, 255, 0.50)',
					itemHoverColor: 'var(--Gradient-Blue, #0267FF)',
					itemSelectedColor: '#ffffff',
				},
			},
		},
	};

	return deviceSize === 'mobile' ? (
		<>
			<SearchBar
				inputRef={inputHeaderRef}
				placeholder="Search games"
				allowClear={true}
				value={''}
				onChange={() => {}}
				onClick={() => {
					inputHeaderRef?.current?.blur();
					setOpenSearchResult(true);
				}}
			/>
			<Drawer
				rootClassName={classes.drawer}
				title="Basic Drawer"
				placement="top"
				closable={false}
				onClose={() => setOpenSearchResult(false)}
				open={openSearchResult}
			>
				<div className="d-flex align-items-center">
					<div className={classes['input-container']}>
						<SearchBar
							inputRef={inputRef}
							placeholder="Search games"
							value={keyword}
							allowClear={true}
							onChange={(e) => setKeyword(e.target.value)}
							style={{ borderColor: 'transparent' }}
							autoFocus={true}
						/>
					</div>
					<a className="mr-2" href="javascript:void(0)" onClick={() => setOpenSearchResult(false)}>
						<ImageC src="/icons/icon-close.svg" height={24} width={24} />
					</a>
				</div>
				<div>
					<ConfigProvider {...configProviderProps}>
						<Tabs className={classes.tabs} defaultActiveKey="1" items={items} tabBarGutter={0} />
					</ConfigProvider>
				</div>
			</Drawer>
		</>
	) : (
		<>
			<Dropdown
				overlayClassName={classes.dropdown}
				trigger={['click']}
				open={openSearchResult}
				onOpenChange={(open) => setOpenSearchResult(open)}
				dropdownRender={() => (
					<div>
						<ConfigProvider {...configProviderProps}>
							<Tabs className={classes.tabs} defaultActiveKey="1" items={items} />
						</ConfigProvider>
					</div>
				)}
			>
				<SearchBar
					placeholder="Search games"
					value={keyword}
					allowClear={true}
					onChange={(e) => setKeyword(e.target.value)}
					onClick={() => setOpenSearchResult(true)}
				/>
			</Dropdown>
		</>
	);
};

export default HeaderSearch;

import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	className?: string;
	width?: number;
	color?: string;
}
const Divider = ({ className = '', width = 1, color = '#858584' }: Props) => {
	return (
		<div
			className={classNames(classes.divider, className)}
			style={{ borderWidth: width, borderColor: color }}
		></div>
	);
};

export default Divider;
